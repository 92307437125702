import React, { useEffect } from 'react'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { getCurrentUser, fetchUserAttributes, fetchMFAPreference } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { BrowserRouter, Outlet, Routes, Route } from 'react-router-dom'

import '@aws-amplify/ui-react/styles.css'
import config from './amplifyconfiguration.json'
import './auth/amplify-styles.css'
import AmplifyAuthComponents from './auth/AmplifyAuthComponents'
import AmplifyAuthForms from './auth/AmplifyAuthForms'
import MFASetupComponent from './auth/MFAComponent'
import Footer from './components/base/Footer'
import Loading from './components/base/Loading'
import NavBar from './components/base/Navbar'
import Home from './components/Home'

Amplify.configure(config)

Hub.listen('auth', (data) => {
  const { payload } = data
  if (payload.event === 'signedIn') {
    getCurrentUser()
      .then((user) => {
        const email = user?.signInDetails?.loginId || ''
        localStorage.setItem('username', email)
      })
      .catch((err) => {
        console.log(err)
      })
  }
})

function PrivateRouter() {
  return (
    <Routes>
      <Route
        element={
          <>
            <NavBar />
            <div style={{ position: 'relative', minHeight: `calc(100vh - 135px)` }}>
              <Outlet />
            </div>
            <Footer />
          </>
        }
      >
        <Route path='/' element={<Home />} />
      </Route>
    </Routes>
  )
}

function App() {
  const { route } = useAuthenticator((context) => [context.route])
  const [userNeedsMFASetup, setUserNeedsMFASetup] = React.useState(false)
  const [isCheckingMFA, setIsCheckingMFA] = React.useState(true)

  useEffect(() => {
    if (route === 'authenticated') {
      setIsCheckingMFA(true)
      fetchUserAttributes()
        .then((user) => {
          return fetchMFAPreference().then((mfaPreference) => {
            return { user, mfaPreference }
          })
        })
        .then(({ user, mfaPreference }) => {
          if (
            user['custom:mfa_required'] === 'true' &&
            (mfaPreference?.enabled?.length === 0 || mfaPreference?.enabled === undefined)
          ) {
            setUserNeedsMFASetup(true)
          }
          setIsCheckingMFA(false)
        })
        .catch((error) => {
          console.error('An error occurred:', error)
        })
    } else {
      setIsCheckingMFA(false)
    }
  }, [route])

  const handleSetUpComplete = () => {
    setUserNeedsMFASetup(false)
  }
  const PrivateRouting = () => {
    if (isCheckingMFA) {
      return <Loading />
    }
    return route === 'authenticated' ? (
      userNeedsMFASetup ? (
        <MFASetupComponent onSetupComplete={handleSetUpComplete} />
      ) : (
        <PrivateRouter />
      )
    ) : (
      <Authenticator
        components={AmplifyAuthComponents}
        formFields={AmplifyAuthForms}
        hideSignUp={true}
      />
    )
  }

  return (
    <BrowserRouter>
      <PrivateRouting />
    </BrowserRouter>
  )
}
export default App
