import { fetchAuthSession } from 'aws-amplify/auth';

export const fetchAccessToken = async (): Promise<string | null> => {
    try {
        const { tokens } = await fetchAuthSession({ forceRefresh: true });
        const accessToken = tokens?.accessToken;

        if (!accessToken) {
            console.warn('No access token found in the session');
            return null;
        }

        return accessToken.toString();
    } catch (err) {
        console.error('Failed to fetch the auth session:', err);
        return null;
    }
};
