import { useState } from 'react';

import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Modal,
    Typography,
    MenuItem,
    InputLabel,
    OutlinedInput,
    ListItemText,
    Checkbox,
    ListItemIcon,
    Chip
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { MATCH_STATUS_MAP } from '../constants/constants';
import { fetchAccessToken } from '../utils/auth';



const POLLING_INTERVAL_MS = 1000;

const statuses = Object.keys(MATCH_STATUS_MAP);

interface DownloadFileModalProps {
    open: boolean;
    onClose: () => void;
    providers: string[];
    startDate: Date;
    endDate: Date;
    downloadFunction: (...args: any[]) => Promise<Response>;  // Using any should allow us to pass any number of arguments, but it's not ideal.
    downloadType: string;
}

const DownloadFileModal = ({
    open,
    onClose,
    providers,
    startDate,
    endDate,
    downloadFunction,
    downloadType
}: DownloadFileModalProps) => {
    const [selectedProvider, setSelectedProvider] = useState('');
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const isAllSelected = statuses.length > 0 && selectedStatuses.length === statuses.length;


    const handleProviderChange = (event: SelectChangeEvent) => {
        setSelectedProvider(event.target.value);
    };

    const handleStatusChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        if (value.includes("All")) {
            setSelectedStatuses(selectedStatuses.length === statuses.length ? [] : statuses);
        } else {
            setSelectedStatuses(value);
        }
    };

    const handleCloseModal = () => {
        setSelectedProvider('');
        setLoading(false);
        setErrorMessage('');
        setSelectedStatuses([]);
        onClose();
    }


    const downloadFileFromUrl = (url: string) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleFetchResponse = async (response: Response, errorMessage: string) => {
        if (!response.ok) {
            setErrorMessage(errorMessage + ` Status: ${response.status}`);
            return { hasError: true };
        }
        return { hasError: false, data: await response.json() };
    };


    const handleDownloadClick = async () => {
        setLoading(true);
        setErrorMessage('');
        let hasError = false;

        try {
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

            const accessToken = await fetchAccessToken();
            if (!accessToken) {
                hasError = true;
                throw new Error('Failed to fetch auth session or missing access token.');
            }

            const response = 
            downloadType === 'Reconciliation'
                ? await downloadFunction(selectedProvider, formattedStartDate, formattedEndDate, selectedStatuses, accessToken)
                : await downloadFunction(selectedProvider, formattedStartDate, formattedEndDate, accessToken);
            const { hasError: downloadError, data } = await handleFetchResponse(response, 'Failed to start download.');
            if (downloadError) return (hasError = true);

            const { execution_id: executionId, status: initialStatus } = data;
            console.log('Execution ID:', executionId);
            console.log('Initial Status:', initialStatus);

            let status;
            let downloadUrl;
            // https://boto3.amazonaws.com/v1/documentation/api/latest/reference/services/athena/client/get_query_execution.html
            const failedStatus = ['FAILED', 'CANCELED'];

            // This is a simple polling mechanism to check the status of the download.
            do {
                await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL_MS));

                try {
                    const statusResponse = await fetch(
                        `${process.env.REACT_APP_ATLAS_DOMAIN}/api/v1/queries/status/${executionId}`, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
                    }
                    );
                    const { hasError: statusError, data: statusData } = await handleFetchResponse(statusResponse, 'Failed to check download status.');
                    if (statusError) return (hasError = true);

                    ({ status, download_url: downloadUrl } = statusData);
                    if (failedStatus.includes(status)) {
                        setErrorMessage('Download failed or was canceled.');
                        hasError = true;
                    }
                    console.log('Status:', status);
                } catch (error) {
                    console.error(`Error during ${downloadType} download status check: ${error}`);
                    setErrorMessage('Failed to check download status.');
                    hasError = true;
                }
            } while (status !== 'SUCCEEDED' && !hasError);

            if (downloadUrl) {
                downloadFileFromUrl(downloadUrl);
            }

        } catch (error) {
            setErrorMessage('An unexpected error occurred.');
            console.error(`Error during ${downloadType} download: ${error}`);
        } finally {
            setLoading(false);
            if (!hasError) {
                handleCloseModal();
            }
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}
            >
                <Typography variant="h6" component="h2" align="center" gutterBottom>
                    Select Provider for {downloadType} Download
                </Typography>

                <Typography variant="body2" align="center" gutterBottom>
                    Download data from: {moment(startDate).format('YYYY-MM-DD')} to {moment(endDate).format('YYYY-MM-DD')}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                    <FormControl component="fieldset" fullWidth>
                        <InputLabel id="provider-select-label">Provider</InputLabel>
                        <Select
                            labelId="provider-select-label"
                            id="provider-select"
                            value={selectedProvider}
                            onChange={handleProviderChange}
                            label="Provider"
                            renderValue={(selected) => (
                                <Chip label={selected} />
                              )}
                        >
                            {providers.map((provider, index) => (
                                <MenuItem key={index} value={provider}>
                                    {provider}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {downloadType === 'Reconciliation' && (
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel id="type-multi-select-label">Match Status</InputLabel>
                            <Select
                                labelId="status-multi-select-label"
                                id="status-multi-select"
                                multiple
                                value={selectedStatuses}
                                onChange={handleStatusChange}
                                input={<OutlinedInput label="Status" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {isAllSelected ? (
                                        <Chip label="All Status" />
                                      ) : (
                                        (selected as string[]).map((value) => (
                                          <Chip key={value} label={value} />
                                        ))
                                      )}
                                    </Box>
                                  )}
                            >
                                <MenuItem value="All">
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={isAllSelected}
                                            indeterminate={selectedStatuses.length > 0 && selectedStatuses.length < statuses.length}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                </MenuItem>

                                {statuses.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        <ListItemIcon>
                                            <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                                        </ListItemIcon>
                                        <ListItemText primary={status} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Button
                        variant="contained"
                        onClick={handleDownloadClick}
                        disabled={!selectedProvider}
                        sx={{ ml: 2 }}
                    >
                        Download Selected Provider
                    </Button>

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    {errorMessage && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {errorMessage}
                        </Typography>
                    )}
                </Box>

            </Box>
        </Modal>
    );
}

export default DownloadFileModal;
