import React, { forwardRef, useState, useReducer } from 'react';


import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid2,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
    Paper,
    Radio,
    RadioGroup,
    FormControlLabel,
    CircularProgress,
    Alert,
    AlertTitle,
    Chip

} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { BarChart } from '@mui/x-charts/BarChart';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { PROVIDER_MAP, MATCH_STATUS_MAP } from '../constants/constants';
import { fetchAccessToken } from '../utils/auth';



import DownloadFileModal from './DownloadFileModal';

const types = ['Charge', 'Refund']
const sources = Object.keys(PROVIDER_MAP);

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
interface CustomDatePickerProps {
    value?: string | Date;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}


interface ProviderData {
    [provider: string]: number; // Maps provider names to values
}

interface Status {
    label: string;
    data: ProviderData;
}


// ChartData is the shape of the data returned from the API
interface ChartData {
    types: string[]; // Array of types, e.g., ['charge', 'refund']
    providers: string[]; // Array of providers e.g ['shopify', 'paypal']
    data: {
        [key: string]: Status[]; // Dynamic key for each type ('charge' or 'refund')
    };
}

const initialState = {
    startDate: moment().subtract(2, 'days').toDate(),
    endDate: null,
    selectedType: 'Charge',
    selectedSources: [],
    chartData: null as ChartData | null,
    modalOpen: null,
    isDataStale: false,
    isInitialLoad: true,
    error: '',
    isLoading: false,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SET_DATE_RANGE':
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                isDataStale: true,
                chartData: null,
            };
        case 'SET_TYPE':
            return {
                ...state,
                selectedType: action.payload,
            };
        case 'SET_SOURCES':
            return {
                ...state,
                selectedSources: action.payload,
                isDataStale: true,
                chartData: null,
            };
        case 'STOP_LOADING':
            return {
                ...state,
                isLoading: false,
            }
        case 'SET_CHART_DATA':
            return {
                ...state,
                chartData: action.payload,
                isDataStale: false,
                isLoading: false,
            };
        case 'RESET_ERROR':
            return {
                ...state,
                error: '',
            };
        case 'RESET_INITIAL_CHART_STATE':
            return {
                ...state,
                isInitialLoad: false,
                isDataStale: false,
                isLoading: true,
                error: '',
            }
        case 'OPEN_MODAL':
            return {
                ...state,
                modalOpen: action.payload,
            };
        case 'CLOSE_MODAL':
            return {
                ...state,
                modalOpen: null,
            };
        case 'SET_ERROR_AND_CLEAR_CHART_DATA':
            return {
                ...state,
                chartData: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

const Home = () => {
    // TODO: We might want to set minDate to a certain date in the past as well
    const maxDate = moment().subtract(2, 'days').toDate();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const isDateRangeSelected = state.startDate && state.endDate;

    const onDateRangeChange = (dates: any) => {
        const [start, end] = dates;
        dispatch({ type: 'SET_DATE_RANGE', payload: { startDate: start, endDate: end } });
        shouldEnableReportBtn(state.selectedType, state.selectedSources, start, end);
    };


    const handleOpenModal = (modalName: string) => {
        dispatch({ type: 'OPEN_MODAL', payload: modalName });
    };

    const handleCloseModal = () => {
        dispatch({ type: 'CLOSE_MODAL', payload: null });
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newType = event.target.value;
        dispatch({ type: 'SET_TYPE', payload: newType });
        shouldEnableReportBtn(state.selectedType, state.selectedSources, state.startDate, state.endDate);
    };

    const handleSourceChange = (event: SelectChangeEvent<typeof state.selectedSources>) => {
        const { value } = event.target;
        const newSources = typeof value === 'string' ? value.split(',') : value;
        dispatch({ type: 'SET_SOURCES', payload: newSources });
        shouldEnableReportBtn(state.selectedType, newSources, state.startDate, state.endDate);
    }

    const CustomDatePicker = forwardRef<HTMLInputElement, CustomDatePickerProps>(({ value, onClick }, ref) => (
        <TextField
            onClick={onClick}
            value={value}
            label="Select Date"
            variant="outlined"
            fullWidth
            inputRef={ref}
        />
    ));
    CustomDatePicker.displayName = 'CustomDatePicker';

    const shouldEnableReportBtn = (newType: string, newProvider: string[], startDate: Date, endDate: Date) => {
        if (newType && newProvider.length > 0 && startDate && endDate) {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    };

    const fetchChartData = async () => {
        if (state.selectedSources.length === 0 || !state.selectedType || !state.startDate || !state.endDate) {
            alert('Please select all required fields');
            return;
        }

        dispatch({ type: 'RESET_INITIAL_CHART_STATE' });
        try {
            const formattedStartDate = moment(state.startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(state.endDate).format('YYYY-MM-DD');

            const mappedProviders = state.selectedSources.map((provider: string) => PROVIDER_MAP[provider]);

            const providersQueryParam = mappedProviders.join(',');
            const queryParams = `?start_date=${encodeURIComponent(formattedStartDate)}&end_date=${encodeURIComponent(formattedEndDate)}&providers=${encodeURIComponent(providersQueryParam)}`;
            const accessToken = await fetchAccessToken();
            if (!accessToken) {
                throw new Error('Failed to fetch auth session or missing access token.');
            }
            const response = await fetch(`${process.env.REACT_APP_ATLAS_DOMAIN}/api/v1/reconciliations/statistics${queryParams}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
                }
            );
            if (!response.ok) {
                throw new Error('Failed to fetch chart data');
            }

            const chartData = await response.json();
            dispatch({ type: 'SET_CHART_DATA', payload: chartData });
        } catch (error) {
            dispatch({ type: 'SET_ERROR_AND_CLEAR_CHART_DATA', payload: 'Failed to fetch chart data' });
            console.error('Error fetching chart data', error);
        } finally {
            dispatch({ type: 'STOP_LOADING' });
        }
    };

    const downloadPayoutFile = async (selectedProvider: string, startDate: string, endDate: string, accessToken: string) => {
        const response = await fetch(`${process.env.REACT_APP_ATLAS_DOMAIN}/api/v1/payouts/download`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                provider: PROVIDER_MAP[selectedProvider],
                'start_date': startDate,
                'end_date': endDate
            }),
        });
        return response;
    }

    const downloadReconFile = async (selectedProvider: string, startDate: string, endDate: string, statuses: any, accessToken: string) => {
        const response = await fetch(`${process.env.REACT_APP_ATLAS_DOMAIN}/api/v1/reconciliations/download`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                provider: PROVIDER_MAP[selectedProvider],
                'start_date': startDate,
                'end_date': endDate,
                'match_status': statuses.map((status: string) => MATCH_STATUS_MAP[status])
            }),
        });
        return response;
    }
    return (
        <Box sx={{ flexGrow: 1, p: 2 }}>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 3 }} sx={{ pl: 10, pt: 5 }}>
                    <Stack direction="column" spacing={2}>
                        {/* zIndex is used to make sure the date picker is on top of most component
                        but lower than the navbar */}
                        <FormControl sx={{ mt: 2, width: 230, position: 'relative', zIndex: 2 }} >
                            <DatePicker
                                showIcon
                                selected={state.startDate}
                                onChange={onDateRangeChange}
                                startDate={state.startDate}
                                endDate={state.endDate}
                                selectsRange={true}
                                maxDate={maxDate}
                                customInput={<CustomDatePicker />}
                            />
                        </FormControl>
                        <Typography sx={{ width: 230 }} align='center' variant='h6'>
                            Reconciliation View
                        </Typography>
                        <Typography sx={{ width: 230 }} align='center' variant="caption">
                            Configure filters to view reconciliation report
                        </Typography>
                        <Paper
                            sx={{
                                pb: 2, width: 230,
                                display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}
                            elevation={3}
                        >
                            <FormControl sx={{ marginTop: 1, width: 200, alignItems: 'center' }}>
                                <RadioGroup
                                    row
                                    aria-label="type"
                                    value={state.selectedType}
                                    onChange={handleTypeChange}
                                >
                                    {types.map((type) => (
                                        <FormControlLabel
                                            key={type}
                                            value={type}
                                            control={<Radio />}
                                            label={type}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <Typography sx={{ marginTop: 1, width: 200 }} align='center'>
                                SOURCE
                            </Typography>
                            <Typography>(Provider ties to GL Account)</Typography>
                            <FormControl sx={{ marginTop: 2, width: 200 }}>
                                <InputLabel id='source-label'>Source</InputLabel>
                                <Select
                                    labelId='source-label'
                                    id='source-id'
                                    multiple
                                    value={state.selectedSources}
                                    onChange={handleSourceChange}
                                    input={<OutlinedInput label='Tag' />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                          {(selected as string[]).map((value) => (
                                            <Chip key={value} label={value} />
                                          ))}
                                        </Box>
                                      )}
                            
                                    MenuProps={MenuProps}
                                >
                                    {sources.map((source) => (
                                        <MenuItem key={source} value={source}>
                                            <Checkbox checked={state.selectedSources.indexOf(source) > -1} />
                                            <ListItemText primary={source} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                disabled={!isButtonEnabled}
                                onClick={fetchChartData}
                                variant='contained'
                                size='large'
                                sx={{ marginTop: 2, width: 200 }}
                                startIcon={<BarChartIcon />}
                            >
                                Show Report
                            </Button>
                        </Paper>
                        <Typography sx={{ width: 230 }} align='center' variant='h6'>
                            Download Options
                        </Typography>
                        <Typography sx={{ width: 230 }} align='center' variant="caption">
                            Export payout and reconciliation files for the selected date range.
                        </Typography>
                        <Paper
                            sx={{
                                pb: 2, width: 230,
                                display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}
                            elevation={3}
                        >
                            <Button
                                onClick={() => handleOpenModal('payout-modal')}
                                size='large'
                                variant='contained'
                                sx={{ marginTop: 2, width: 200 }}
                                startIcon={<DownloadIcon />}
                                disabled={!isDateRangeSelected}
                            >
                                Download Payout
                            </Button>
                            <DownloadFileModal
                                open={state.modalOpen === 'payout-modal'}
                                onClose={handleCloseModal}
                                providers={sources}
                                startDate={state.startDate}
                                endDate={state.endDate}
                                downloadFunction={downloadPayoutFile}
                                downloadType='Payout'
                            />
                            <Button
                                onClick={() => handleOpenModal('recon-modal')}
                                variant='contained'
                                sx={{ marginTop: 2, width: 200 }}
                                startIcon={<DownloadIcon />}
                                disabled={!isDateRangeSelected}
                            >
                                Download Reconciliation
                            </Button>
                            <DownloadFileModal
                                open={state.modalOpen === 'recon-modal'}
                                onClose={handleCloseModal}
                                providers={sources}
                                startDate={state.startDate}
                                endDate={state.endDate}
                                downloadFunction={downloadReconFile}
                                downloadType='Reconciliation'
                            />
                        </Paper>
                    </Stack>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }} sx={{ pt: 5 }}>
                    <Paper sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }} elevation={3}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingX: 2, pt: 2 }}>
                            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }} color="textSecondary">
                                <FilterAltIcon />
                                Active Filters
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                gap: 2,
                                paddingX: 2,
                                pb: 2,
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Typography variant="body1" fontWeight="bold">
                                    Providers:
                                </Typography>
                                <Chip
                                    label={
                                        state.selectedSources.length === 0
                                            ? 'No Provider'
                                            : state.selectedSources.length === sources.length
                                                ? 'All Providers'
                                                : state.selectedSources.join(', ')
                                    }
                                    sx={{
                                        borderRadius: '10px',
                                    }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Typography variant="body1" fontWeight="bold">
                                    Type:
                                </Typography>
                                <Chip label={state.selectedType} sx={{
                                    borderRadius: '10px',
                                }} />
                            </Box>

                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Typography variant="body1" fontWeight="bold">
                                    Date Range:
                                </Typography>
                                <Chip
                                    icon={<CalendarTodayIcon />}
                                    label={`${moment(state.startDate).format('YYYY-MM-DD')} to ${moment(state.endDate).format('YYYY-MM-DD')}`}
                                    sx={{
                                        borderRadius: '10px',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ padding: 3, width: '100%', height: 500, mt: 5 }} elevation={3}>
                        {state.isLoading ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                                <CircularProgress size={60} />
                            </Box>
                        ) : state.error ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                                <Alert severity="error" onClose={() => dispatch({ type: 'RESET_ERROR' })} sx={{ mb: 2 }}>
                                    <AlertTitle>Error</AlertTitle>
                                    {state.error}
                                </Alert>
                            </Box>
                        ) : state.isInitialLoad ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                                <Typography variant="h6" color="textSecondary">
                                    Select provider and date range to show report
                                </Typography>
                            </Box>
                        ) : state.isDataStale ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                                <Typography variant="h6" color="textSecondary">
                                    Selections updated. Click `Show Recon Report` to refresh data.
                                </Typography>
                            </Box>
                        ) : !state.chartData?.types.includes(state.selectedType.toLowerCase()) ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height={400}>
                                <Typography variant="h6" color="textSecondary">
                                    No data available for {state.selectedType}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ height: 'calc(100% - 50px)' }}>
                                <BarChart
                                    margin={{ top: 100 }}
                                    sx={{ height: '100%', width: '100%' }}
                                    series={state.chartData.data[state.selectedType.toLowerCase()].map((status: Status) => ({
                                        label: status.label,
                                        data: state.selectedSources.map((provider: string) => status.data[provider.toLowerCase()] || 0),
                                        stack: 'MatchStatus',
                                    }))}
                                    xAxis={[{ data: state.selectedSources, label: 'Providers', scaleType: 'band' }]}
                                />
                            </Box>
                        )}
                    </Paper>

                </Grid2>

                <Grid2 size={{ xs: 12, md: 3 }} sx={{ pl: 15, pt: 5 }}>
                    <Typography>Data Last Updated: XX/XX/XX</Typography>
                    <Typography>(Data Source: xxxxxxx)</Typography>
                    <Typography>Last Payout File Date: XX/XX/XX</Typography>
                </Grid2>
            </Grid2>
        </Box >
    );
}

export default Home;
